import { useEffect, useState, useMemo } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import { isValidAddress } from "@xmtp/react-sdk";
import Swal from "sweetalert2";

import useAPI from "../../utils/api";
import { useVault, useSnackbar, useWeb3 } from "../../context";
import SuiInput from "../../components/soft-ui/SuiInput";
import SuiButton from "../../components/soft-ui/SuiButton";
import SuiBox from "../../components/soft-ui/SuiBox";
import SuiTypography from "../../components/soft-ui/SuiTypography";
import MediaFileUpload from "../../components/MediaFileUpload";
import { magicProvider } from "config/magic";

const initialFilesInfo = {
  files: [],
  filePreviews: [],
};

const initialProductInfo = {
  name: "",
  description: "",
  price: "",
  walletAddress: "",
};

export default function AddProductDialog({ open, onClose, productData, callback }) {
  const { user, account } = useVault();
  const api = useAPI();
  const { showSnackbar } = useSnackbar();
  const { escrowFactoryContract } = useWeb3();
  const [productInfo, setProductInfo] = useState(initialProductInfo);
  const [filesInfo, setFilesInfo] = useState(initialFilesInfo);
  const [errMessage, setErrMessage] = useState("");
  const [saving, setSaving] = useState(false);

  const isEdit = useMemo(() => !!productData, [productData]);

  const createListing = async () => {
    try {
      const gasPrice = await magicProvider.getGasPrice();
      const tx = await escrowFactoryContract.createListing({ gasPrice, gasLimit: 800000 });
      const receipt = await tx.wait();

      const event = receipt.events.find((event) => event.event === "ListingCreated");

      if (event && event.args) {
        const listingAddress = event.args.listingAddress;
        return listingAddress;
      } else {
        throw Error("ListingCreated event not found in transaction receipt");
      }
    } catch (error) {
      throw Error("Failed to create escrow contract");
    }
  };

  const handleSave = async () => {
    setErrMessage("");

    if (!filesInfo.files.length && !filesInfo.filePreviews.length) {
      setErrMessage("Please select product image");
      return;
    } else if (!productInfo.name) {
      setErrMessage("Please input product name");
      return;
    } else if (!productInfo.price) {
      setErrMessage("Please input product price");
      return;
    } else if (Number(productInfo.price) <= 0) {
      setErrMessage("Please input valid price");
      return;
    } else if (!productInfo.walletAddress) {
      setErrMessage("Please input wallet address");
      return;
    } else if (!isValidAddress(productInfo.walletAddress)) {
      setErrMessage("Please input valid wallet address");
      return;
    }

    const productFormData = new FormData();
    for (let i = 0; i < filesInfo.files.length; i += 1) {
      productFormData.append("files", filesInfo.files[i]);
    }
    productFormData.append("name", productInfo.name);
    productFormData.append("description", productInfo.description);
    productFormData.append("price", productInfo.price);
    productFormData.append("wallet_address", productInfo.walletAddress);

    if (isEdit) {
      productFormData.append("productId", productData.id);
    }

    setSaving(true);

    try {
      if (!user.is_admin && !isEdit) {
        const listingAddress = await createListing();
        productFormData.append("listing_address", listingAddress);
      }

      await api("save_product", productFormData, true);
      onClose();
      callback();

      if (user.is_admin) {
        setProductInfo(initialProductInfo);
      } else {
        setProductInfo({ ...initialProductInfo, walletAddress: account.address });
      }
      setFilesInfo(initialFilesInfo);

      const message = isEdit ? "Updated successfully" : "Added successfully";
      showSnackbar("Success", message, "info");

      if (!user.is_admin) {
        await Swal.fire({
          title: "Listing Submission Received",
          text: "Thank you for submitting your item to the Patriot Pay Classifieds section! Your listing is currently under review and may take up to 24 hours to be approved. Please contact us at support@patriotpay.org if you have any questions",
          icon: "info",
          confirmButtonText: "OK",
        });
      }
    } catch (err) {
      showSnackbar("Error", "Failed to save product", "error");
    }

    setSaving(false);
  };

  useEffect(() => {
    if (user.is_admin) return;

    setProductInfo({ ...initialProductInfo, walletAddress: account.address });
  }, [account.address, user.is_admin]);

  useEffect(() => {
    if (!isEdit) return;

    const updatedProductInfo = {
      ...initialProductInfo,
      name: productData.name,
      description: productData.description,
      price: productData.price.toString(),
      walletAddress: productData.wallet_address,
    };

    const updatedFilesInfo = {
      ...initialFilesInfo,
      filePreviews: productData.attachments.map((att) => att.image_url),
    };

    setProductInfo(updatedProductInfo);
    setFilesInfo(updatedFilesInfo);
  }, [isEdit, productData]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{isEdit ? "Edit" : "Add a new"} product</DialogTitle>
      <DialogContent sx={{ minWidth: { sm: "400px" } }}>
        {errMessage && (
          <SuiTypography color="error" fontSize="12px" mb={1}>
            {errMessage}
          </SuiTypography>
        )}
        <SuiBox mb={2}>
          <DialogContentText>Images</DialogContentText>
          <MediaFileUpload filesInfo={filesInfo} isMultiple handleUpdateFiles={setFilesInfo} />
        </SuiBox>
        <SuiBox mb={2}>
          <DialogContentText>Name</DialogContentText>
          <SuiInput
            value={productInfo.name}
            error={!productInfo.name}
            onChange={(e) => setProductInfo({ ...productInfo, name: e.target.value })}
          />
        </SuiBox>
        <SuiBox mb={2}>
          <DialogContentText>Description</DialogContentText>
          <SuiInput
            value={productInfo.description}
            multiline
            rows={5}
            onChange={(e) => setProductInfo({ ...productInfo, description: e.target.value })}
          />
        </SuiBox>
        <SuiBox mb={2}>
          <DialogContentText>Price($)</DialogContentText>
          <SuiInput
            type="number"
            value={productInfo.price}
            error={!productInfo.price || Number(productInfo.price) <= 0}
            onChange={(e) => setProductInfo({ ...productInfo, price: e.target.value })}
          />
        </SuiBox>
        <SuiBox>
          <DialogContentText>Wallet Address</DialogContentText>
          {user.is_admin ? (
            <SuiInput
              value={productInfo.walletAddress}
              error={!productInfo.walletAddress || !isValidAddress(productInfo.walletAddress)}
              onChange={(e) => setProductInfo({ ...productInfo, walletAddress: e.target.value })}
            />
          ) : (
            <SuiInput value={productInfo.walletAddress} disabled />
          )}
        </SuiBox>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <SuiButton
          color="primary"
          circular
          sx={{ width: "105px" }}
          disabled={saving}
          onClick={handleSave}
        >
          {saving ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : "Save"}
        </SuiButton>
        <SuiButton variant="outlined" color="primary" circular onClick={onClose}>
          Cancel
        </SuiButton>
      </DialogActions>
    </Dialog>
  );
}
