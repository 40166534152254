import { useState } from "react";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";

import SuiButton from "components/soft-ui/SuiButton";
import { useSnackbar } from "context";
import useAPI from "utils/api";
import { magicProvider } from "config/magic";

export default function DisputeButton({ product, listingContract, styles, callback }) {
  const api = useAPI();
  const { showSnackbar } = useSnackbar();
  const [disputing, setDisputing] = useState(false);

  const handleDispute = async () => {
    setDisputing(true);

    try {
      const gasPrice = await magicProvider.getGasPrice();
      const tx = await listingContract.raiseDispute({ gasPrice, gasLimit: 800000 });
      await tx.wait();

      await api("update_product", { productId: product.id, data: { state: "Disputed" } });

      showSnackbar("Success", "Disputed successfully", "info");
      callback();
    } catch (err) {
      showSnackbar("Error", "Failed to dispute", "error");
    }

    setDisputing(false);
  };

  const onDisputeProduct = async () => {
    await Swal.fire({
      text: `Are you sure? Please click 'Confirm'`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await handleDispute();
      }
    });
  };

  return (
    <>
      <SuiButton
        color="error"
        circular
        sx={{ textTransform: "capitalize", ...styles }}
        disabled={disputing}
        onClick={onDisputeProduct}
      >
        {disputing ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : "Dispute"}
      </SuiButton>
    </>
  );
}
