import { ethers } from "ethers";
import { parseUnits, formatUnits } from "ethers/lib/utils";

export const extraPricePrecision = 2;

export const formatPrice = (item, amount) => {
  const result = formatUnits(
    (amount ?? item.price).div(parseUnits("1", extraPricePrecision)),
    item.pxdec
  );

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumSignificantDigits: 21,
  }).format(result);
};

export const formatAmount = (item, amount) =>
  formatUnits(amount.div(parseUnits("1", item.decimals - item.display)), item.display);

export const formatAmountDecimal = (item, amount) => {
  const result = formatAmount(item, amount);

  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    grouping: true,
    maximumSignificantDigits: 21,
  }).format(result);
};

export const parseQuote = (item, px) =>
  parseUnits(
    Number(px).toFixed(item.pxdec + extraPricePrecision),
    item.pxdec + extraPricePrecision
  );

export const parseAvailable = (balances, item) =>
  parseUnits(`${Number(balances?.[item.symbol] ?? 0).toFixed(item.decimals)}`, item.decimals);

export const formatValueInUSD = (value, price, pxdec, decimals) => {
  const precision = pxdec + extraPricePrecision;
  return formatUnits(value.mul(price).div(parseUnits("1", decimals + precision - 2)), 2);
};

export const formatWalletValue = (balances, item, usesmartwallet) => {
  const balance = balances.get(item.symbol) || [ethers.BigNumber.from(0), ethers.BigNumber.from(0)];
  const value = usesmartwallet ? balance[1] : balance[0];

  const result = formatValueInUSD(value, item.price, item.pxdec, item.decimals);

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumSignificantDigits: 21,
  }).format(result);
};

export const formatCurrency = (value = 0) =>
  value.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });

export const formatDecimal = (number) => {
  let result = Number(number).toFixed(2);
  if (!Number(result)) {
    result = Number(number).toFixed(6);
  }

  return result;
};
