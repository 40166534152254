export const adjustSymbol = (currency) => (currency === "$FJB" ? "PPY" : currency);

export const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return `${text.slice(0, maxLength)}...`;
  }
  return text;
};

export const shortenAddress = (address) => `${address.slice(0, 4)}...${address.slice(-4)}`;

export const isSameAddress = (addr1, addr2) => addr1?.toLowerCase() === addr2?.toLowerCase();
