import { useState } from "react";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";

import SuiButton from "components/soft-ui/SuiButton";
import { useSnackbar } from "context";
import useAPI from "utils/api";
import { magicProvider } from "config/magic";

export default function ConfirmDelivery({ product, listingContract, styles, callback }) {
  const api = useAPI();
  const { showSnackbar } = useSnackbar();
  const [confirming, setConfirming] = useState(false);

  const handleConfirm = async () => {
    setConfirming(true);

    try {
      const gasPrice = await magicProvider.getGasPrice();
      const tx = await listingContract.confirmDelivery({ gasPrice, gasLimit: 800000 });
      await tx.wait();

      await api("update_product", { productId: product.id, data: { state: "Sold" } });

      showSnackbar("Success", "Confirmed successfully", "info");
      callback();
    } catch (err) {
      showSnackbar("Error", "Failed to confirm", "error");
    }

    setConfirming(false);
  };

  const onConfirmDelivery = async () => {
    await Swal.fire({
      text: `Are you sure? Please click 'Confirm'`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await handleConfirm();
      }
    });
  };

  return (
    <>
      <SuiButton
        color="primary"
        circular
        sx={{ textTransform: "capitalize", ...styles }}
        disabled={confirming}
        onClick={onConfirmDelivery}
      >
        {confirming ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : "Confirm Delivery"}
      </SuiButton>
    </>
  );
}
