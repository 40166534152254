import { useEffect, useState, useMemo } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import { isValidAddress } from "@xmtp/react-sdk";

import useAPI from "utils/api";
import { useSnackbar } from "context";
import SuiInput from "components/soft-ui/SuiInput";
import SuiButton from "components/soft-ui/SuiButton";
import SuiBox from "components/soft-ui/SuiBox";
import SuiTypography from "components/soft-ui/SuiTypography";
import MediaFileUpload from "components/MediaFileUpload";

const initialFileInfo = {
  files: [],
  filePreviews: [],
};

const initialCandidateInfo = {
  name: "",
  position: "",
  website: "",
  description: "",
  walletAddress: "",
};

export default function AddCandidateDialog({ open, onClose, candidateData: cData, callback }) {
  const api = useAPI();
  const { showSnackbar } = useSnackbar();
  const [candidateInfo, setCandidateInfo] = useState(initialCandidateInfo);
  const [fileInfo, setFileInfo] = useState(initialFileInfo);
  const [errMessage, setErrMessage] = useState("");
  const [saving, setSaving] = useState(false);

  const isEdit = useMemo(() => !!cData, [cData]);

  const handleSave = async () => {
    setErrMessage("");

    if (!fileInfo.files.length && !fileInfo.filePreviews.length) {
      setErrMessage("Please select candidate image");
      return;
    } else if (!candidateInfo.name) {
      setErrMessage("Please input candidate name");
      return;
    } else if (!candidateInfo.position) {
      setErrMessage("Please select candidate position");
      return;
    } else if (!candidateInfo.website) {
      setErrMessage("Please input candidate website");
      return;
    } else if (!candidateInfo.walletAddress) {
      setErrMessage("Please input wallet address");
      return;
    } else if (!isValidAddress(candidateInfo.walletAddress)) {
      setErrMessage("Please input valid wallet address");
      return;
    }

    const candidateFormData = new FormData();
    if (!!fileInfo.files.length) {
      candidateFormData.append("file", fileInfo.files[0]);
    }
    candidateFormData.append("name", candidateInfo.name);
    candidateFormData.append("position", candidateInfo.position);
    candidateFormData.append("website", candidateInfo.website);
    candidateFormData.append("description", candidateInfo.description);
    candidateFormData.append("wallet_address", candidateInfo.walletAddress);

    if (isEdit) {
      candidateFormData.append("candidateId", cData.id);
    }

    setSaving(true);

    try {
      await api("save_candidate", candidateFormData, true);
      onClose();
      callback();

      setCandidateInfo(initialCandidateInfo);
      setFileInfo(initialFileInfo);

      const message = isEdit ? "Updated successfully" : "Added successfully";
      showSnackbar("Success", message, "info");
    } catch (err) {
      showSnackbar("Error", "Failed to save candidate", "error");
    }

    setSaving(false);
  };

  useEffect(() => {
    if (!isEdit) return;

    const updatedCandidateInfo = {
      ...initialCandidateInfo,
      name: cData.name,
      position: cData.position,
      website: cData.website,
      description: cData.description,
      walletAddress: cData.wallet_address,
    };

    const updatedFileInfo = {
      ...initialFileInfo,
      filePreviews: [cData.photo],
    };

    setCandidateInfo(updatedCandidateInfo);
    setFileInfo(updatedFileInfo);
  }, [isEdit, cData]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{isEdit ? "Edit" : "Add a new"} candidate</DialogTitle>
      <DialogContent sx={{ minWidth: { sm: "400px" } }}>
        {errMessage && (
          <SuiTypography color="error" fontSize="12px" mb={1}>
            {errMessage}
          </SuiTypography>
        )}
        <SuiBox mb={2}>
          <DialogContentText>Photo</DialogContentText>
          <MediaFileUpload filesInfo={fileInfo} handleUpdateFiles={setFileInfo} />
        </SuiBox>
        <SuiBox mb={2}>
          <DialogContentText>Name</DialogContentText>
          <SuiInput
            value={candidateInfo.name}
            error={!candidateInfo.name}
            onChange={(e) => setCandidateInfo({ ...candidateInfo, name: e.target.value })}
          />
        </SuiBox>
        <SuiBox mb={2}>
          <DialogContentText>Position</DialogContentText>
          <SuiInput
            value={candidateInfo.position}
            error={!candidateInfo.position}
            onChange={(e) => setCandidateInfo({ ...candidateInfo, position: e.target.value })}
          />
        </SuiBox>
        <SuiBox mb={2}>
          <DialogContentText>Website</DialogContentText>
          <SuiInput
            value={candidateInfo.website}
            error={!candidateInfo.website}
            onChange={(e) => setCandidateInfo({ ...candidateInfo, website: e.target.value })}
          />
        </SuiBox>
        <SuiBox mb={2}>
          <DialogContentText>Description</DialogContentText>
          <SuiInput
            value={candidateInfo.description}
            multiline
            rows={4}
            onChange={(e) => setCandidateInfo({ ...candidateInfo, description: e.target.value })}
          />
        </SuiBox>
        <SuiBox>
          <DialogContentText>Wallet Address</DialogContentText>
          <SuiInput
            value={candidateInfo.walletAddress}
            error={!candidateInfo.walletAddress || !isValidAddress(candidateInfo.walletAddress)}
            onChange={(e) => setCandidateInfo({ ...candidateInfo, walletAddress: e.target.value })}
          />
        </SuiBox>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <SuiButton
          color="primary"
          circular
          sx={{ width: "105px" }}
          disabled={saving}
          onClick={handleSave}
        >
          {saving ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : "Save"}
        </SuiButton>
        <SuiButton variant="outlined" color="primary" circular onClick={onClose}>
          Cancel
        </SuiButton>
      </DialogActions>
    </Dialog>
  );
}
