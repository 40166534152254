import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid, Tab, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import styled from "@emotion/styled";
import { ethers } from "ethers";

import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import SuiBox from "../components/soft-ui/SuiBox";
import SuiTypography from "../components/soft-ui/SuiTypography";
import AssetCard from "../components/AssetCard";
import SuiButton from "../components/soft-ui/SuiButton";
import Navbar from "../components/layout/Navbar";
import LightweightChart from "../components/LightweightChart";
import SwapOrderForm from "../components/SwapOrderForm";
import AcceptTermsDialog from "../components/dialog/AcceptTermsDialog";
import SeedDialog from "../components/dialog/SeedDialog";
import Receive from "../components/Receive";

import useAPI from "../utils/api";
import { useQuotes } from "../context";
import {
  formatPrice,
  formatAmount,
  parseAvailable,
  parseQuote,
  formatWalletValue,
  formatAmountDecimal,
} from "../utils/numeric";
import { useBalances } from "../utils/balances";
import { CONTRACTS } from "../context/web3";
import { getSmartWalletAddress } from "../utils/contracts";
import popular from "../config/popular.json";
import { useVault } from "../context/vault";

import coinPpy from "../assets/images/coin-logos/PPY.svg";
import coinBtc from "../assets/images/coin-logos/BTC.svg";
import coinEth from "../assets/images/coin-logos/ETH.svg";
import coinSol from "../assets/images/coin-logos/SOL.svg";
import coinMatic from "../assets/images/coin-logos/MATIC.svg";
import coinUsdc from "../assets/images/coin-logos/USDC.svg";
import coinUsdt from "../assets/images/coin-logos/USDT.svg";
import coinBNB from "../assets/images/coin-logos/BNB.svg";
// import coinLink from "assets/images/coin-logos/LINK.svg";
import backArrow from "../assets/images/icons/back-arrow.svg";
import OnramperDialog from "../components/dialog/OnramperDialog";
import OrderHistory from "../features/orders/OrderHistory";

const coinOp =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQ3SURBVHgB7ZtbbFRVFEDXFKalxPAIIj4Qi6WlBWsADRHrI4jYaOq7Ej4wFfRDA0L9gCCJj0RCQ/THWOtHQ3x8qFEjaExMEZPSpoEaSRXBKpVAIq9WEa1PQjv13Bwm597O3G4+zjA/eyWTnH3PmZnMunfvfc9MJjE8tmQYJZYClFFRQQIqSEAFCaggARUkoIIEVJCAChJQQQIqSEAFCaggARUkoIIEVJCAChJQQQIqSEAFCaggARUkoIIEVJCAChJQQQJjuRiMHwe33gRLquHqK8yBBLR3wc52OHw0/nnzq2DTajtOBb+Qp9zckIl7j8CXndBhXms4N7+gJ3L623yBEbH+SfuYOCH7mk93wVMbof905ty6VfDKc4gcPQZPrIfde/FN7lJs2qWwYxts3hAvJ+C+O6HtQ3Mtj8mcqyzjgiiZDh+3wMJ5+CY3gooKoWkz3L3YHQvSacVa8yFqoWYFbG91c2Uz4dk1ma9TUerGPT/BLQ+ZVH0YbjOPJcvh1W0utSZcAo0b8U1uatDqenigxsXN70DDi9E68fV+uGwKVN9o47UmnRqbYfCcjRMmPStmufU/Hoau7uj7BHVs5gxzFS618aIFMK4I/juLL/xfQWNMqqx5zMVBfVj3QmYRHfgTWne7eJJJw9IZLp5TDlMmu/jb78nKwV43TiahuBif+Be0cpnpVFe6eGtz/NrjJ6PxnFDNWXBddK7rG7IyN/Scc+bqGxjAJ/4F3bvUjYeG4O2P4tcWFo6Ik248skB3ZOlQ9XUuvQL2mhQcSuET/zWoMlQ39vfYsxpHUIPCnPrVja+vcON//jUyltk0TaWs2PvvgjtudmuCucbX8Y1fQQUFtuWm6f9t9PXlpdE4fNN47TVuPN7UlaaXiOWsKcpPPw9ftOMbv4KCDhJ0nzRnfo9fGxTzqtku/vkEHDtfk4rNnXdZCSKDg/DBZ/DyG3DgELnAr6DgTAYplTxfS0a7/a+53aRRpYt3dbjxohuia1veg86v7DhhrtJBU9tO9pkUNp3tjN+iPBK/goICGVwJ6fQI16ORNDzuxoHILU0uXjg/urblXeg+QD7w38V27HTjeXPhkdrofNKck62bYHGowL72pr1fSlNV7sZ//W2LfZ7wv1m96nJztj+HyZNsHHSg9z8xH/IHc2wi1N1j7l1Ctaf7oN1+hOnc7vZVe/aZrUUd+cJ/mz9+CjZssV2nqMh2oFXLs69tbYNHGzKPV4Va/KEj5JPcbFbfMrvz6gdh33fZ54NtQ/0zULvSFNk/onOzSmwXS9PTSz5J5Py/GtPNF2Szzf3OVHNT2Ndv2/Evp+PXT5tqv1hL07YHTvSRLxL6Z5bR0e+kBVSQgAoSUEECKkhABQmoIAEVJKCCBFSQgAoSUEECKkhABQmoIAEVJKCCBFSQgAoSUEECKkhABQmoIAEVJKCCBFSQwP/r3fNwstl7WwAAAABJRU5ErkJggg==";

const IMAGE_MAP = {
  PPY: coinPpy,
  USDT: coinUsdt,
  MUSDC: coinUsdc,
  BUSDC: coinUsdc,
  USDC: coinUsdc,
  MATIC: coinMatic,
  SOL: coinSol,
  BTC: coinBtc,
  WBTC: coinBtc,
  ETH: coinEth,
  BNB: coinBNB,
  OETH: coinEth,
  OP: coinOp,
};

const StyledTabList = styled(TabList)(({ theme }) => ({
  background: "white",
  padding: 0,
  borderRadius: 0,

  "& button.MuiTab-root": {
    borderRadius: 0,
    padding: theme.spacing(1.5),
    fontSize: "14px",
    fontWeight: 500,
    color: `${theme.palette.primary.light} !important`,

    "&.Mui-disabled": {
      opacity: "0.6 !important",
    },

    "&.Mui-selected": {
      color: `${theme.palette.primary.dark} !important`,
    },
  },

  "& .MuiTabs-indicator": {
    height: 2,
    borderRadius: 0,
    boxShadow: "none",
    backgroundColor: theme.palette.secondary.main,
  },
}));

function Assets() {
  const {
    account,
    accepted_legal_terms: acceptedLegalTerms,
    refresh,
    wallet: legacyWallet,
    user,
    newSeed,
  } = useVault();
  const api = useAPI();
  const [selectedItem, setSelectedItem] = useState(null);
  const [swapProps, setSwapProps] = useState();
  const [onramper, setOnramper] = useState("");
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  const quotes = useQuotes();
  const [allItems, setAllItems] = useState([]);
  const [items, setItems] = useState([]);
  const [showDepositAddress, setShowDepositAddress] = useState(false);
  const [acceptWait, setAcceptWait] = useState(false);
  const [seedSaved, setSeedSaved] = useState(false);
  const { balances } = useBalances(legacyWallet?.availableBalances);
  const navigate = useNavigate();
  const { hash } = useLocation();
  const [wasDeep, setDeep] = useState(false);
  const [receiveAddress, setReceiveAddress] = useState();
  const [stable, setStable] = useState();
  const [hide, setHide] = useState(false);
  const [tab, setTab] = useState("market-tab");

  const useSmartWallet = user?.use_smart_wallet;

  useEffect(() => {
    if (selectedItem && account.address) {
      setReceiveAddress(
        selectedItem.native || !user?.use_smart_wallet
          ? account.address
          : getSmartWalletAddress(CONTRACTS.FreedomFactory.address, account.address)
      );
    } else {
      setReceiveAddress(undefined);
    }
  }, [user?.use_smart_wallet, account.address, selectedItem]);

  // @Find
  useEffect(() => {
    if (hash === "") {
      setStable(undefined);
    } else {
      const alt = hash.replace("#", "");
      if (alt === "stable") {
        setStable(true);
      } else if (alt === "!stable") {
        setStable(false);
      } else {
        setStable(undefined);
      }
    }
  }, [hash]);

  useEffect(() => {
    if (selectedItem === null && items.length > 0 && hash !== "") {
      const alt = hash.replace("#", "");
      const item =
        alt === "!ppy" ? items[0] : items.find((option) => option.alt.toLowerCase() === alt);

      if (item) {
        setDeep(true);
        setSelectedItem(item);
        // until notfications are working, touching it will refresh balances :)
        refresh();
      }
    }
  }, [selectedItem, items, hash, refresh]);

  const getAvailable = useCallback(
    (item) => balances.get(item.symbol) ?? [ethers.BigNumber.from(0), ethers.BigNumber.from(0)],
    [balances]
  );

  useEffect(() => {
    const allCoinsInfo = popular.map((item) => {
      const quote = quotes[item.alt] ? `${quotes[item.alt].rate}` : item.price;
      let price;
      try {
        price = parseQuote(item, quote);
      } catch (err) {
        console.log("Assets setItems parseQuote error", err);
        price = ethers.BigNumber.from(0);
      }
      const avl = getAvailable(item);
      return { ...item, price, available: useSmartWallet ? avl[1] : avl[0] };
    });

    setAllItems(allCoinsInfo);

    let visibleItems =
      selectedItem === null
        ? stable === undefined
          ? allCoinsInfo
          : allCoinsInfo.filter((x) => !!x.stable === !!stable)
        : allCoinsInfo.filter((x) => x.symbol === selectedItem.symbol);

    const alt = hash.replace("#", "");
    if (alt === "!ppy") {
      visibleItems = visibleItems.slice(0, 1);
    }

    setItems(visibleItems);
    //
    // There is a race condition hinting that there may be a better way to chain
    // effects and callbacks.  If this check is removed on a deep link the view
    // will render on a stale getAvailable() value and will not update unless
    // the user does something that calls refresh()
    //
    if (selectedItem !== null && selectedItem.symbol === visibleItems[0].symbol) {
      selectedItem.available = visibleItems[0].available;
    }
  }, [getAvailable, quotes, selectedItem, stable, hash, useSmartWallet]);

  const handleBuy = useCallback(
    async (item) => {
      if (item.symbol === "PPY") {
        const maticItem = popular.find((x) => x.symbol === "MATIC");
        // const quote = quotes[maticItem.alt]
        //   ? `${quotes[maticItem.alt].rate}`
        //   : maticItem.price;
        // let price;
        // try {
        //   price = parseQuote(maticItem, quote);
        // } catch (err) {
        //   console.log("Assets setItems parseQuote error", err);
        //   price = ethers.BigNumber.from(0);
        // }
        const avl = getAvailable(maticItem);
        const availableAmount = useSmartWallet ? avl[1] : avl[0];
        // const updatedMaticItem = {
        //   ...maticItem,
        //   price,
        //   available: availableAmount,
        // };

        setSwapProps({
          type: "BUY",
          sourceNetwork: maticItem.network,
          sourceCurrency: maticItem.symbol,
          sourceDecimals: maticItem.decimals,
          sourceAddress: receiveAddress,
          sourceAvailable: formatAmount(maticItem, availableAmount),
          sourceAmount: "",
          destCurrency: item.symbol,
          destNetwork: item.network,
          destAddress: receiveAddress,
          txType: "Buy",
        });
      } else {
        setOnramper("buy");
      }
    },
    [receiveAddress, getAvailable, useSmartWallet]
  );

  const handleSell = useCallback(
    async (item) => {
      // these sellings are disabled in onramper
      if (["PPY", "WBTC"].includes(item.symbol)) {
        setSwapProps({
          type: "SELL",
          sourceNetwork: item.network,
          sourceCurrency: item.symbol,
          sourceDecimals: item.decimals,
          sourceAddress: receiveAddress,
          sourceAvailable: formatAmount(
            item,
            useSmartWallet ? getAvailable(item)[1] : getAvailable(item)[0]
          ),
          sourceAmount: "",
          destCurrency: "USDT",
          destNetwork: "polygon",
          destAddress: receiveAddress,
          txType: "Sell",
        });
      } else {
        setOnramper("sell");
      }
    },
    [receiveAddress, getAvailable, useSmartWallet]
  );

  const handleTrade = useCallback(
    (item) => {
      const itemNative = popular.filter((x) => x.native && x.chainId === item.chainId)[0];
      setSwapProps({
        type: "TRADE",
        sourceNetwork: item.network,
        sourceCurrency: item.symbol,
        sourceDecimals: item.decimals,
        sourceAddress: receiveAddress,
        sourceAvailable: formatAmount(
          item,
          useSmartWallet ? getAvailable(item)[1] : getAvailable(item)[0]
        ),
        sourceAmount: "",
        destCurrency: useSmartWallet ? "PPY" : itemNative.symbol,
        destNetwork: useSmartWallet ? "polygon" : itemNative.network,
        destAddress: receiveAddress,
        txType: "Trade",
      });
    },
    [receiveAddress, getAvailable, useSmartWallet]
  );

  const handleSend = useCallback(
    (item) => {
      setSwapProps({
        type: "SEND",
        sourceNetwork: item.network,
        sourceCurrency: item.symbol,
        sourceDecimals: item.decimals,
        sourceAddress: receiveAddress,
        sourceAvailable: formatAmount(
          item,
          useSmartWallet ? getAvailable(item)[1] : getAvailable(item)[0]
        ),
        sourceAmount: "",
        destCurrency: item.symbol,
        destNetwork: item.network,
        txType: "Transfer",
      });
    },
    [receiveAddress, getAvailable, useSmartWallet]
  );

  const handleClaim = useCallback(
    (item) => {
      const sourceAvailable = formatAmount(
        item,
        parseAvailable(legacyWallet?.availableBalances, item)
      );
      setSwapProps({
        type: "CLAIM",
        locked: true,
        sourceNetwork: item.network,
        sourceCurrency: item.symbol,
        sourceDecimals: item.decimals,
        sourceAddress: process.env.REACT_APP_AGENT_ADDRESS,
        sourceAmount: sourceAvailable,
        sourceAvailable,
        destCurrency: item.symbol,
        destNetwork: item.network,
        destAddress: account.address,
        txType: "Claim",
      });
    },
    [account, legacyWallet]
  );

  const handleSelect = (item) => {
    setSelectedItem(item);
    setShowDepositAddress(false);
    setSwapProps(null);
    // until notfications are working, touching it will refresh balances :)
    refresh();
  };

  const handleSendClose = () => {
    setSwapProps(null);
    refresh();
    // TODO: Also, if a new order was created, display a popup with a link to view the order status.
  };

  const handleBackButton = () => {
    if (selectedItem === null || wasDeep) {
      navigate("/home");
    } else {
      setSelectedItem(null);
      setSwapProps(null);
      setShowDepositAddress(false);
      setOnramper("");
    }
  };

  const handleReceive = () => {
    setShowDepositAddress(true);
  };

  const handleAcceptLegalTerms = async () => {
    setAcceptWait(true);
    if (!newSeed || seedSaved) {
      await api("config/accept-terms");
      refresh();
    }
  };

  const handleSeedDialog = () => {
    setSeedSaved(true);
    api("config/accept-terms").then(() => refresh());
  };

  const hasEscrowPPY = legacyWallet?.availableBalances?.PPY > 0 ?? false;

  return (
    <SuiBox>
      <Header />

      <AcceptTermsDialog
        open={!acceptWait && !acceptedLegalTerms}
        onAccept={handleAcceptLegalTerms}
      />
      <SeedDialog
        open={(acceptWait || acceptedLegalTerms) && newSeed && !seedSaved}
        onAccept={handleSeedDialog}
      />

      <SuiBox
        display="flex"
        flexDirection={{ xs: "column", lg: "row" }}
        justifyContent={{ xs: "flex-start", lg: "stretch" }}
        maxWidth="100%"
        p={{ xs: 1.5, lg: 2.5 }}
        pb={{ xs: 2, lg: 2.5 }}
        minHeight="calc(100vh - 57px)"
      >
        <SuiBox
          mr={{ xs: 0, lg: 2.5 }}
          width={{ xs: "100%", lg: "450px" }}
          minWidth={{ xs: "100%", lg: "450px" }}
        >
          <Navbar hideZeroBalance={setHide} />
          <SuiBox
            mt={2}
            bgColor="white"
            border="0px solid rgba(0, 0, 0, 0.125)"
            borderRadius="lg"
            overflow="hidden"
            boxShadow="rgba(0, 0, 0, 0.1) 0rem 1.25rem 1.6875rem 0rem"
            minHeight={{ xs: 0, lg: "calc(100vh - 234px)" }}
          >
            <TabContext value={tab}>
              <SuiBox sx={{ borderBottom: 1, borderColor: "divider" }}>
                <StyledTabList
                  value={tab}
                  onChange={(_, newTab) => setTab(newTab)}
                  aria-label="disabled tabs example"
                >
                  <Tab label="Market" value="market-tab" />
                  <Tab label="Orders" value="orders-tab" disabled={selectedItem === null} />
                </StyledTabList>
              </SuiBox>
              <SuiBox maxHeight="calc(100vh - 50px)" overflow="auto">
                <TabPanel value="market-tab">
                  <SuiBox mb={2}>
                    <SuiButton
                      color="dark"
                      circular
                      size="small"
                      fullWidth
                      display="flex !important"
                      justifyContent="space-between"
                      alignItems="space-between"
                      variant="outlined"
                      onClick={handleBackButton}
                    >
                      <img src={backArrow} alt="back arrow" width="20px" pr={2} />
                      &nbsp;&nbsp;&nbsp;BACK
                    </SuiButton>
                  </SuiBox>
                  <Grid container spacing={{ xs: 1.75, md: 3 }}>
                    {items.map(
                      (item) =>
                        (item.symbol === "$PPY" ||
                          item.symbol === "PPY" ||
                          (hide ? Number(item.available) > 0 : true)) && (
                          <Grid
                            item
                            xs={12}
                            {...(selectedItem === null ? { xl: 12 } : {})}
                            key={item.symbol}
                          >
                            <SuiButton
                              disableRipple
                              color="white"
                              variant="contained"
                              fullWidth
                              onClick={() => handleSelect(item)}
                            >
                              <AssetCard
                                title={{
                                  text: item.symbol === "$FJB" ? "PPY" : item.symbol,
                                  fontWeight: "bold",
                                }}
                                price={formatPrice(item)}
                                percentage={{
                                  color: item.chg >= 0 ? "success" : "error",
                                  text:
                                    item.chg > 0
                                      ? `+${item.chg}%`
                                      : item.chg < 0
                                      ? `${item.chg}%`
                                      : "",
                                }}
                                coinCount={formatAmountDecimal(item, item.available)}
                                coinValue={formatWalletValue(balances, item, useSmartWallet)}
                                image={IMAGE_MAP[item.symbol]}
                                alttext={item.symbol}
                              />
                            </SuiButton>
                          </Grid>
                        )
                    )}
                  </Grid>
                  <OnramperDialog
                    open={!!onramper}
                    onClose={() => setOnramper("")}
                    mode={onramper}
                    symbol={selectedItem?.symbol}
                    network={selectedItem?.network}
                    address={onramper === "matic_ppy" ? account.address : receiveAddress}
                    userId={user.id}
                  />
                  {swapProps ? (
                    <>
                      <SwapOrderForm
                        {...swapProps}
                        currencyItems={allItems}
                        onClose={handleSendClose}
                      />
                      {selectedItem && selectedItem.symbol === "PPY" && swapProps.type === "BUY" && (
                        <SuiBox mt="20px">
                          <SuiTypography fontSize="0.75rem" fontWeight="bold" color="#14294f">
                            Don&apos;t have enough Matic to buy PPY? Use a bank card to get Matic
                            now.
                          </SuiTypography>
                          <SuiBox mt="10px">
                            <SuiButton
                              color="primary"
                              fullWidth
                              onClick={() => setOnramper("matic_ppy")}
                            >
                              Get MATIC
                            </SuiButton>
                          </SuiBox>
                        </SuiBox>
                      )}
                    </>
                  ) : showDepositAddress ? (
                    <Receive key={receiveAddress} asset={selectedItem} address={receiveAddress} />
                  ) : selectedItem === null ? null : (
                    <>
                      {selectedItem.symbol === "PPY" && hasEscrowPPY ? (
                        <SuiBox mt={{ xs: 4, lg: 4 }} mb={2} width="100%">
                          <SuiButton
                            color="secondary"
                            circular
                            fullWidth
                            onClick={() => handleClaim(selectedItem)}
                            disabled={!account || !account.address}
                          >
                            Claim legacy balance
                          </SuiButton>
                        </SuiBox>
                      ) : null}
                      <SuiBox
                        mt={{ xs: 4, lg: 4 }}
                        display="flex"
                        alignContent="center"
                        justifyContent="space-between"
                        fullWidth
                      >
                        <SuiBox width="48%">
                          <SuiButton
                            color="secondary"
                            circular
                            fullWidth
                            onClick={() => handleBuy(selectedItem)}
                          >
                            {selectedItem.stable ? "DEPOSIT" : "BUY"}
                          </SuiButton>
                        </SuiBox>
                        <SuiBox width="48%">
                          <SuiButton
                            color="secondary"
                            circular
                            fullWidth
                            disabled={selectedItem.available.isZero()}
                            onClick={() => handleSell(selectedItem)}
                          >
                            {selectedItem.stable ? "WITHDRAW" : "SELL"}
                          </SuiButton>
                        </SuiBox>
                      </SuiBox>
                      <SuiBox
                        mt={{ xs: 2, lg: 2 }}
                        display="flex"
                        alignContent="center"
                        justifyContent="space-between"
                        fullWidth
                      >
                        <SuiBox width="48%">
                          <SuiButton
                            color="secondary"
                            circular
                            fullWidth
                            onClick={() => handleSend(selectedItem)}
                          >
                            SEND
                          </SuiButton>
                        </SuiBox>
                        <SuiBox width="48%">
                          <SuiButton
                            color="secondary"
                            circular
                            fullWidth
                            onClick={() => handleReceive(selectedItem)}
                            disabled={!account || !receiveAddress}
                          >
                            RECEIVE
                          </SuiButton>
                        </SuiBox>
                      </SuiBox>
                      <SuiBox
                        mt={{ xs: 2, lg: 2 }}
                        display="flex"
                        alignContent="center"
                        justifyContent="space-between"
                        fullWidth
                      >
                        <SuiBox width="100%">
                          <SuiButton
                            color="dark"
                            variant="outlined"
                            circular
                            fullWidth
                            disabled={selectedItem.available.isZero()}
                            onClick={() => handleTrade(selectedItem)}
                          >
                            TRADE
                          </SuiButton>
                        </SuiBox>
                      </SuiBox>
                    </>
                  )}
                  {!lgUp && selectedItem !== null && (
                    <LightweightChart
                      asset={selectedItem ? selectedItem.alt : "PPY"}
                      height={xlUp ? 680 : lgUp ? 500 : 220}
                    />
                  )}
                </TabPanel>
                <TabPanel value="orders-tab">
                  <OrderHistory selectedItem={selectedItem} />
                </TabPanel>
              </SuiBox>
            </TabContext>
          </SuiBox>
        </SuiBox>
        {lgUp && (
          <LightweightChart
            asset={selectedItem ? selectedItem.alt : "PPY"}
            height={xlUp ? 680 : lgUp ? 500 : 220}
          />
        )}
      </SuiBox>
      <Footer />
    </SuiBox>
  );
}

export default Assets;
